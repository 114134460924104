import SortIcon from "@mui/icons-material/Sort";
import { Button as RaButton, useListSortContext } from "react-admin";

const ResetSortButton = ({ field, order }) => {
  const { setSort } = useListSortContext();
  const handleChangeSort = (event) => {
    setSort({
      field,
      order,
    });
  };

  return (
    <RaButton label="Reset sort" onClick={handleChangeSort}>
      <SortIcon />
    </RaButton>
  );
};

export default ResetSortButton;
