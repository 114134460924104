import { IconButton } from "@mui/material";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import clsx from "clsx";
import { humanize, singularize } from "inflection";
import {
  useDeleteWithConfirmController,
  useRecordContext,
  useResourceContext,
  useTranslate,
} from "react-admin";

import { Confirm } from "./Confirm";

export const DeleteButton = (props) => {
  const {
    className,
    confirmTitle = "ra.message.delete_title",
    confirmContent = "ra.message.delete_content",
    confirmColor = "primary",
    icon = defaultIcon,
    mutationMode = "pessimistic",
    onClick,
    redirect = "list",
    translateOptions = {},
    mutationOptions,
    color = "error",
    ...rest
  } = props;
  const translate = useTranslate();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);

  const { open, isLoading, handleDialogOpen, handleDialogClose, handleDelete } =
    useDeleteWithConfirmController({
      record,
      redirect,
      mutationMode,
      onClick,
      mutationOptions,
      resource,
    });

  return (
    <>
      <IconButton
        onClick={handleDialogOpen}
        className={clsx("ra-delete-button", className)}
        key="button"
        color={color}
        {...rest}
      >
        {icon}
      </IconButton>
      <Confirm
        isOpen={open}
        loading={isLoading}
        title={confirmTitle}
        content={confirmContent}
        confirmColor={confirmColor}
        translateOptions={{
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: 1,
            _: humanize(
              translate(`resources.${resource}.name`, {
                smart_count: 1,
                _: singularize(resource),
              }),
              true,
            ),
          }),
          id: record?.id,
          ...translateOptions,
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  );
};

const defaultIcon = <DeleteOutlinedIcon />;
