import { useEffect } from "react";
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import {
  AutocompleteInput,
  Create,
  DateInput,
  Edit,
  SaveButton,
  SelectInput,
  SimpleForm,
  TextInput,
  Toolbar,
  useCreatePath,
  useDataProvider,
  useNotify,
  useRedirect,
} from "react-admin";
import { Button, Card, CardHeader, Container } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import _ from "lodash";

import { countries } from "../resources/countries";

const FormToolbar = (props) => (
  <Toolbar sx={{ justifyContent: "right" }}>
    <SaveButton
      color="black"
      sx={{ textTransform: "none" }}
      icon={<SaveOutlinedIcon />}
    />
  </Toolbar>
);

const BackButton = ({ destination }) => {
  const createPath = useCreatePath();
  return (
    <Button
      component={Link}
      to={
        destination ||
        createPath({
          resource: "users",
          type: "list",
        })
      }
      color="yellow"
      variant="contained"
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        fontWeight: "bold",
        textTransform: "none",
      }}
      startIcon={<ArrowBackIcon />}
    >
      Back
    </Button>
  );
};

const diagnoses = [
  { id: "Autism Spectrum Disorder", name: "Autism Spectrum Disorder" },
  { id: "Speech Apraxia", name: "Speech Apraxia" },
  { id: "Learning Disability", name: "Learning Disability" },
  { id: "Down Syndrome", name: "Down Syndrome" },
  { id: "Other", name: "Other" },
];

const ChildForm = (props) => {
  return (
    <SimpleForm sx={{ px: 3 }} toolbar={<FormToolbar />} {...props}>
      <TextInput source="first_name" fullWidth />
      <TextInput source="last_name" fullWidth />
      <SelectInput
        source="gender"
        fullWidth
        choices={[
          { id: "M", name: "Male" },
          { id: "F", name: "Female" },
          { id: "O", name: "Other" },
          { id: "N", name: "Prefer not to say" },
        ]}
        emptyText="Prefer not to say"
        emptyValue="N"
      />
      <DateInput
        source="birthdate"
        fullWidth
        inputProps={{
          min: "0001-01-01",
          max: "9999-12-31",
        }}
      />
      <AutocompleteInput
        source="country"
        fullWidth
        choices={countries.map((c) => ({ id: c.label, name: c.label }))}
      />
      <AutocompleteInput source="diagnosis" fullWidth choices={diagnoses} />
    </SimpleForm>
  );
};

const transform = (data) => {
  const transformedData = { ...data };
  if (data.birthdate) {
    console.log(data.birthdate);
    transformedData.birthdate = data.birthdate.replace(
      /(^\w+)/,
      (match, p1, offset, string) =>
        _.clamp(Number(p1), 1, 9999).toString().padStart(4, "0"),
    );
    console.log(transformedData.birthdate);
  }
  return transformedData;
};

export const ChildCreate = (props) => {
  const dataProvider = useDataProvider();
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();
  const [searchParams] = useSearchParams();
  const parent = searchParams.get("parent");
  const navigate = useNavigate();
  const { state } = useLocation();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    dataProvider
      .getList("children", {
        filter: { parent },
        pagination: { page: 1 },
        sort: { id: "ASC" },
      })
      .then(({ data }) => {
        if (data.length) redirect("list", "users");
      });
    // eslint-disable-next-line
  }, []);

  const onSuccess = (data) => {
    notify("Child created.");
    if (state?.redirectPath) {
      navigate(state?.redirectPath);
    } else {
      redirect("list", "users");
    }
  };

  const onError = (error) => {
    notify("Could not create child.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton destination={state?.redirectPath} />
      <Container maxWidth="sm">
        <Create
          title="New Child"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          transform={transform}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Child"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <ChildForm defaultValues={{ parent }} />
          </Card>
        </Create>
      </Container>
    </Container>
  );
};

export const ChildEdit = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();
  const navigate = useNavigate();
  const { state } = useLocation();

  const onSuccess = (data) => {
    notify("Child updated.");
    if (state?.redirectPath) {
      navigate(state?.redirectPath);
    } else {
      redirect("list", "users");
    }
  };

  const onError = (error) => {
    notify("Could not update child.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton destination={state?.redirectPath} />
      <Container maxWidth="sm">
        <Edit
          title="Edit Child"
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          transform={transform}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Child"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <ChildForm />
          </Card>
        </Edit>
      </Container>
    </Container>
  );
};
