import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  BooleanField,
  BooleanInput,
  BulkActionsToolbar,
  ChipField,
  Create,
  CreateButton,
  Datagrid,
  downloadCSV,
  Edit,
  EditBase,
  EditButton,
  ExportButton,
  FormDataConsumer,
  List,
  NumberField,
  NumberInput,
  RecordContextProvider,
  ReferenceManyCount,
  ReferenceManyField,
  required,
  SaveButton,
  SearchInput,
  SelectInput,
  Show,
  SimpleForm,
  SingleFieldList,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useCreatePath,
  useDataProvider,
  useListContext,
  useNotify,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useShowContext,
  useStore,
  WithRecord,
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Tooltip as MuiTooltip,
  Typography,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import InfoIcon from "@mui/icons-material/Info";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

import { inflect } from "inflection";
import jsonExport from "jsonexport/dist";
import _ from "lodash";

import { AnswerList } from "./answers";
import { BulkDeleteButton } from "./BulkDeleteButton";
import { BulkExportButton } from "./BulkExportButton";
import { QuestionChoiceList } from "./questionChoices";
import ResetSortButton from "./ResetSortButton";
import rawDataProvider from "../provider/dataProvider";

const asideWidth = "400px";

const AsideOptions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const createPath = useCreatePath();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="question-aside-button"
        aria-controls={open ? "question-aside-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="black"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="question-aside-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={createPath({
            type: "show",
            resource,
            id: record.id,
          })}
        >
          More info
        </MenuItem>
        <MenuItem
          component={Link}
          to={createPath({
            type: "edit",
            resource,
            id: record.id,
          })}
        >
          Edit
        </MenuItem>
      </Menu>
    </div>
  );
};

const QuestionListAsideCard = (props) => {
  const theme = useTheme();
  return (
    <Card sx={{ mt: "8px", mb: "3em", ml: "1em", width: asideWidth }}>
      <CardHeader
        action={props.action}
        sx={{
          backgroundColor: theme.palette.yellow.main,
          py: props.action ? "3px" : "7px",
        }}
        title={props.title}
        titleTypographyProps={{
          variant: "span",
          fontWeight: "bold",
        }}
      />
      <CardContent
        sx={{
          "& .RaLabeled-label": { fontWeight: "bold" },
          "& fieldset": {
            borderColor: theme.palette.yellow.main,
            borderRadius: "12px",
          },
          "& legend": {
            fontSize: "12px",
            fontWeight: "bold",
          },
        }}
      >
        {props.children || <Stack spacing={1} />}
      </CardContent>
    </Card>
  );
};

const QuestionListAside = (props) => {
  const { data, isPending, selectedIds } = useListContext();
  if (isPending) return null;
  if (!data) return null;
  if (!data.length) return null;
  const record = data?.find((e) => e.id === props.selection);
  if (!record)
    return (
      <QuestionListAsideCard
        title={`${selectedIds.length} ${inflect("question", selectedIds.length)} selected`}
      />
    );
  if (!props.selection)
    return <QuestionListAsideCard title="No question selected" />;
  return (
    <RecordContextProvider value={record}>
      <QuestionListAsideCard action={<AsideOptions />} title={record.name}>
        <Stack spacing={1}>
          {record.name && (
            <Box component="fieldset">
              <legend>Question</legend>
              <TextField source="name" />
            </Box>
          )}
          {record.description && (
            <Box component="fieldset">
              <legend>Description</legend>
              <TextField source="description" />
            </Box>
          )}
          <Box component="fieldset">
            <legend>Is required</legend>
            <BooleanField source="is_required" />
          </Box>
          {record.type && (
            <Box component="fieldset">
              <legend>Type</legend>
              <TextField source="type" />
            </Box>
          )}
          {record.type === "Choices" && (
            <Box component="fieldset">
              <legend>Choices</legend>
              <ReferenceManyField
                reference="question-choices"
                target="question_id"
              >
                <SingleFieldList>
                  <ChipField source="value" />
                </SingleFieldList>
              </ReferenceManyField>
            </Box>
          )}
          {record.type === "Rating" && (
            <>
              {(record.min_rating || record.min_rating === 0) && (
                <Box component="fieldset">
                  <legend>Min Rating</legend>
                  <NumberField source="min_rating" />
                </Box>
              )}
              {(record.max_rating || record.max_rating === 0) && (
                <Box component="fieldset">
                  <legend>Max Rating</legend>
                  <NumberField source="max_rating" />
                </Box>
              )}
            </>
          )}
          <Box component="fieldset">
            <legend>Total respondents</legend>
            <ReferenceManyCount
              label="Total respondents"
              reference="answers"
              target="question"
            />
          </Box>
        </Stack>
      </QuestionListAsideCard>
    </RecordContextProvider>
  );
};

const exporter = async (questions, fetchRelatedRecords) => {
  const surveyId = questions[0].survey;
  const surveyResults = (await rawDataProvider.getSurveyResults(surveyId)).data;
  const questionsForExport = questions.map((question) => {
    const { id, name, type, is_required } = question;
    const questionWithResults = surveyResults.find((r) => r.id === id);
    let total_respondents;
    switch (type) {
      case "Rating":
        total_respondents = questionWithResults.results.reduce(
          (n, result) => n + result.count,
          0,
        );
        break;
      case "Choice":
        total_respondents = questionWithResults.results.reduce(
          (n, result) => n + result.count,
          0,
        );
        break;
      case "Text":
        total_respondents = questionWithResults.results.length;
        break;
      default:
        console.error("Unknown question type.");
    }
    return { name, type, is_required, total_respondents };
  });
  jsonExport(
    questionsForExport,
    {
      headers: ["name", "type", "is_required", "total_respondents"],
      rename: ["Question", "Type", "Is required", "Total respondents"],
    },
    (err, csv) => {
      downloadCSV(csv, "questions"); // download as 'questions.csv` file
    },
  );
};

export const QuestionList = (props) => {
  const [selection, setSelection] = useStore(
    props.selectionStore ?? "question.list",
    null,
  );
  const [selectedRecord, setSelectedRecord] = useStore(
    `${props.selectionStore}.record` ?? "question.list.record",
    null,
  );
  const theme = useTheme();

  return (
    <>
      <List
        {...props}
        actions={
          props.actions ?? (
            <TopToolbar>
              <ResetSortButton field="created_at" order="DESC" />
              <CreateButton />
              <ExportButton />
            </TopToolbar>
          )
        }
        aside={<QuestionListAside selection={selection} />}
        exporter={exporter}
        filters={[<SearchInput key="search" source="search" alwaysOn />]}
        sort={{ field: "created_at", order: "DESC" }}
        sx={{ m: 2 }}
      >
        <Datagrid
          bulkActionsToolbar={
            <BulkActionsToolbar label="1 question selected |||| %{smart_count} questions selected">
              <BulkExportButton color="white" />
              <BulkDeleteButton
                mutationMode="pessimistic"
                color="white"
                deleteSuccessMessage="Question deleted |||| %{smart_count} questions deleted"
              />
            </BulkActionsToolbar>
          }
          rowClick={(id, resource, record) => {
            setSelection(id);
            setSelectedRecord(record);
            return false;
          }}
          rowSx={(record, index) => ({
            backgroundColor:
              record.id === selection ? theme.palette.lightYellow.main : null,
          })}
        >
          <TextField source="name" label="Question" />
          <TextField source="type" />
          <BooleanField source="is_required" />
          <ReferenceManyCount
            label="Total respondents"
            reference="answers"
            target="question"
          />
        </Datagrid>
      </List>
      <RecordContextProvider value={selectedRecord}>
        <WithRecord render={(record) => <QuestionResults record={record} />} />
      </RecordContextProvider>
    </>
  );
};

const QuestionShowData = (props) => {
  const theme = useTheme();
  const { record } = useShowContext();
  if (!record) return null;
  return (
    <Stack direction="row">
      <Card sx={{ flexGrow: 1, m: 2 }}>
        <CardContent
          sx={{
            "& .RaLabeled-label": { fontWeight: "bold" },
            "& fieldset": {
              borderColor: theme.palette.yellow.main,
              borderRadius: "12px",
              m: 1,
            },
            "& legend": {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {record.name && (
              <Box component="fieldset">
                <legend>Question</legend>
                <TextField source="name" />
              </Box>
            )}
            {record.description && (
              <Box component="fieldset">
                <legend>Description</legend>
                <TextField source="description" />
              </Box>
            )}
            <Box component="fieldset" sx={{ textAlign: "center" }}>
              <legend>Is required</legend>
              <BooleanField source="is_required" />
            </Box>
            {record.type && (
              <Box component="fieldset">
                <legend>Type</legend>
                <TextField source="type" />
              </Box>
            )}
            {record.type === "Choices" && (
              <Box component="fieldset">
                <legend>Choices</legend>
                <ReferenceManyField
                  reference="question-choices"
                  target="question_id"
                >
                  <SingleFieldList>
                    <ChipField source="value" />
                  </SingleFieldList>
                </ReferenceManyField>
              </Box>
            )}
            {record.type === "Rating" && (
              <>
                {record.min_rating && (
                  <Box component="fieldset">
                    <legend>Min Rating</legend>
                    <NumberField source="min_rating" />
                  </Box>
                )}
                {record.max_rating && (
                  <Box component="fieldset">
                    <legend>Max Rating</legend>
                    <NumberField source="max_rating" />
                  </Box>
                )}
              </>
            )}
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ ml: 2, width: asideWidth }} />
    </Stack>
  );
};

const QuestionChoices = (props) => {
  const theme = useTheme();
  const { record } = useShowContext();
  if (!record) return null;
  // if (record.type !== "Choice") return null;
  return (
    <QuestionChoiceList
      actions={
        <TopToolbar>
          <MuiTooltip
            title="A choice cannot be edited once a response has been recorded for it."
            arrow
          >
            <InfoIcon sx={{ color: theme.palette.black.main }} />
          </MuiTooltip>
          <ResetSortButton field="created_at" order="DESC" />
          <CreateButton
            resource="question-choices"
            state={{ record: { question: record.id } }}
          />
          <ExportButton />
        </TopToolbar>
      }
      disableSyncWithLocation
      resource="question-choices"
      title=" "
      filter={{ question: record.id }}
      selectionStore={`question.choices.selection.${record.id}`}
      storeKey={`question.choices.${record.id}`}
      empty={
        <Stack direction="row">
          <Box textAlign="center" mx="auto">
            <Typography variant="h4" paragraph>
              No choices in this question
            </Typography>
            <CreateButton
              resource="question-choices"
              state={{ record: { question: record.id } }}
            />
          </Box>
          <Box sx={{ ml: 2, width: asideWidth }} />
        </Stack>
      }
    />
  );
};

const QuestionAnswers = (props) => {
  const { record } = useShowContext();
  if (!record) return null;
  return (
    <AnswerList
      actions={
        <TopToolbar>
          <ExportButton />
        </TopToolbar>
      }
      disableSyncWithLocation
      resource="answers"
      title=" "
      filter={{ question: record.id }}
      selectionStore={`question.answers.selection.${record.id}`}
      storeKey={`question.answers.${record.id}`}
      listType={record.type === "Rating" ? "rating" : "answer"}
      empty={
        <Stack direction="row">
          <Box textAlign="center" mx="auto">
            <Typography variant="h4" paragraph>
              No responses in this question
            </Typography>
          </Box>
          <Box sx={{ ml: 2, width: asideWidth }} />
        </Stack>
      }
    />
  );
};

export const QuestionChart = ({
  isAnimationActive = true,
  record,
  results,
  type,
  width,
  height,
}) => {
  if (type === "Rating") {
    const emptyRatings = [];
    for (
      let i = record.min_rating ?? 1;
      i < (record.max_rating ?? 5) + 1;
      i++
    ) {
      emptyRatings.push({ rating: i, count: 0 });
    }
    const paddedResults = _.unionBy(results, emptyRatings, "rating");
    const sortedResults = _.sortBy(paddedResults, (e) => e.rating);
    return (
      <BarChart data={sortedResults} height={height} width={width}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="rating" />
        <YAxis allowDecimals={false} />
        {isAnimationActive && <Tooltip />}
        <Legend />
        <Bar
          isAnimationActive={isAnimationActive}
          dataKey="count"
          fill="#8884d8"
        />
      </BarChart>
    );
  }
  if (type === "Choice") {
    const validResults = results.filter((row) => row.answer.trim());
    return (
      <BarChart
        data={validResults}
        height={height}
        width={width}
        layout="vertical"
        margin={{ left: 100 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis type="number" allowDecimals={false} />
        <YAxis type="category" dataKey="answer" />
        {isAnimationActive && <Tooltip />}
        <Legend />
        <Bar
          isAnimationActive={isAnimationActive}
          dataKey="count"
          fill="#8884d8"
        />
      </BarChart>
    );
  }
  return null;
};

export const QuestionResults = ({ record }) => {
  const dataProvider = useDataProvider();
  const { data, isPending, error } = useQuery({
    enabled: record.id !== undefined,
    queryKey: ["questions", "getQuestionResults", { id: record.id }],
    queryFn: ({ signal }) => dataProvider.getQuestionResults(record.id),
  });

  if (isPending) return null;
  if (!record) return null;
  if (error)
    return (
      <Stack direction="row">
        <Box textAlign="center" mx="auto">
          <Typography variant="h4" paragraph>
            Something went wrong
          </Typography>
        </Box>
        <Box sx={{ ml: 2, width: asideWidth }} />
      </Stack>
    );
  if (!data) return null;
  const json = data.data;
  if (!json.results.length)
    return (
      <Stack direction="row">
        <Box textAlign="center" mx="auto">
          <Typography variant="h4" paragraph>
            No responses in this question
          </Typography>
        </Box>
        <Box sx={{ ml: 2, width: asideWidth }} />
      </Stack>
    );
  if (json.type === "Rating") {
    return (
      <Stack direction="row">
        <Card sx={{ flexGrow: 1, m: 2, p: 2 }}>
          <ResponsiveContainer height={220} width="99%">
            <QuestionChart
              record={record}
              results={json.results}
              type={json.type}
            />
          </ResponsiveContainer>
        </Card>
        <Box sx={{ ml: 2, minWidth: asideWidth }} />
      </Stack>
    );
  }
  if (json.type === "Choice") {
    return (
      <Stack direction="row">
        <Card sx={{ flexGrow: 1, m: 2, p: 2 }}>
          <ResponsiveContainer
            height={Math.max(220, json.results.length * 48)}
            width="99%"
          >
            <QuestionChart
              record={record}
              results={json.results}
              type={json.type}
            />
          </ResponsiveContainer>
        </Card>
        <Box sx={{ ml: 2, minWidth: asideWidth }} />
      </Stack>
    );
  }
  if (json.type === "Text") {
    const validResults = json.results.filter((row) => row.answer.trim());
    return (
      <Stack direction="row">
        <TableContainer component={Paper} sx={{ m: 2 }}>
          <Table size="small" aria-label="Question text results">
            <TableBody>
              {validResults.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell scope="row">{row.answer}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box sx={{ ml: 2, minWidth: asideWidth }} />
      </Stack>
    );
  }
  return null;
};

export const QuestionShow = (props) => {
  const createPath = useCreatePath();
  return (
    <Show
      actions={
        <TopToolbar>
          <WithRecord
            render={(record) => (
              <EditButton
                state={{
                  redirectPath: createPath({
                    resource: "questions",
                    type: "show",
                    id: record.id,
                  }),
                }}
              />
            )}
          />
          <Box sx={{ ml: 2, width: asideWidth }} />
        </TopToolbar>
      }
      component={Box}
      title="Questions"
      {...props}
    >
      <WithRecord
        render={(record) => (
          <BackButton
            destination={createPath({
              resource: "surveys",
              type: "show",
              id: record.survey,
            })}
          />
        )}
      />
      <Box sx={{ py: 2 }} />
      <Typography variant="h5" sx={{ mx: 2 }}>
        Info
      </Typography>
      <QuestionShowData />
      <WithRecord
        render={(record) =>
          record.type === "Choice" && (
            <>
              <Typography variant="h5" sx={{ mx: 2 }}>
                Choices (Choice-type only)
              </Typography>
              <QuestionChoices />
            </>
          )
        }
      />
      <Typography variant="h5" sx={{ mx: 2 }}>
        Responses
      </Typography>
      <QuestionAnswers />
      {/* Needed to avoid useQuery issues */}
      <Typography variant="h5" sx={{ mx: 2 }}>
        Results
      </Typography>
      <WithRecord render={(record) => <QuestionResults record={record} />} />
    </Show>
  );
};

const FormToolbar = (props) => (
  <Toolbar sx={{ justifyContent: "right" }}>
    <SaveButton
      color="black"
      sx={{ textTransform: "none" }}
      icon={<SaveOutlinedIcon />}
    />
  </Toolbar>
);

const BackButton = ({ destination }) => {
  return (
    <Button
      component={Link}
      to={destination}
      color="yellow"
      variant="contained"
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        fontWeight: "bold",
        textTransform: "none",
      }}
      startIcon={<ArrowBackIcon />}
    >
      Back
    </Button>
  );
};

const QuestionForm = (props) => (
  <SimpleForm sx={{ px: 3 }} toolbar={<FormToolbar />} {...props}>
    <TextInput source="name" label="Question" fullWidth validate={required()} />
    <TextInput source="description" fullWidth multiline />
    <SelectInput
      source="type"
      choices={[
        { id: "Text", name: "Text" },
        { id: "Choice", name: "Choice" },
        { id: "Rating", name: "Rating" },
      ]}
      fullWidth
      validate={required()}
    />
    <FormDataConsumer>
      {({ formData, ...rest }) =>
        formData.type === "Rating" ? (
          <>
            <NumberInput
              source="min_rating"
              {...rest}
              disabled
              min={1}
              step={1}
              defaultValue={1}
              fullWidth
              helperText="Support for more values coming soon"
            />
            <NumberInput
              source="max_rating"
              {...rest}
              disabled
              min={1}
              step={1}
              defaultValue={5}
              fullWidth
              helperText="Support for more values coming soon"
            />
          </>
        ) : null
      }
    </FormDataConsumer>
    <BooleanInput source="is_required" />
  </SimpleForm>
);

export const QuestionCreate = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();
  const createPath = useCreatePath();
  const { state } = useLocation();

  const onSuccess = (data) => {
    notify("Question created.");
    redirect("show", "surveys", data.survey);
  };

  const onError = (error) => {
    notify(error.message || "Could not create question.", { type: "error" });
  };

  console.log(state);

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton
        destination={createPath({
          resource: "surveys",
          type: "show",
          id: state?.record?.survey,
        })}
      />
      <Container maxWidth="sm">
        <Create
          title="New Question"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Question"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <QuestionForm />
          </Card>
        </Create>
      </Container>
    </Container>
  );
};

export const QuestionEdit = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();
  const createPath = useCreatePath();
  const { state } = useLocation();
  const navigate = useNavigate();

  const onSuccess = (data) => {
    notify("Question updated.");
    if (state?.redirectPath) {
      navigate(state?.redirectPath);
    } else {
      redirect("show", "surveys", data.survey);
    }
  };

  const onError = (error) => {
    notify(error.message || "Could not update question.", { type: "error" });
  };

  return (
    <Container maxWidth={false} sx={{ my: { xs: 3 }, px: { xs: 0 } }}>
      <EditBase {...props}>
        <WithRecord
          render={(record) => (
            <BackButton
              destination={
                state?.redirectPath ||
                createPath({
                  resource: "surveys",
                  type: "show",
                  id: record.survey,
                })
              }
            />
          )}
        />
      </EditBase>
      <Container maxWidth="sm" sx={{}}>
        <Edit
          title="Edit Question"
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Question"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <QuestionForm />
          </Card>
        </Edit>
      </Container>
    </Container>
  );
};
