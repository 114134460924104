import { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import {
  BulkActionsToolbar,
  BooleanInput,
  Button as RaButton,
  Create,
  CreateButton,
  Datagrid,
  DateField,
  DateTimeInput,
  downloadCSV,
  Edit,
  EditButton,
  ExportButton,
  FunctionField,
  List,
  RecordContextProvider,
  required,
  SaveButton,
  SearchInput,
  Show,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useCreatePath,
  useDataProvider,
  useListContext,
  useNotify,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useShowContext,
  useStore,
  // WithListContext,
  WithRecord,
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { saveAs } from "file-saver";
import { inflect } from "inflection";
import jsonExport from "jsonexport/dist";
import {
  Document,
  Page,
  pdf,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import ReactPDFChart from "react-pdf-charts";

import { BulkDeleteButton } from "./BulkDeleteButton";
import { BulkExportButton } from "./BulkExportButton";
import ResetSortButton from "./ResetSortButton";
import { QuestionChart, QuestionList } from "./questions";
// import { QuestionList, QuestionResults } from "./questions";

const asideWidth = "400px";

const AsideOptions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const createPath = useCreatePath();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="survey-aside-button"
        aria-controls={open ? "survey-aside-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="black"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="survey-aside-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={createPath({
            type: "show",
            resource,
            id: record.id,
          })}
        >
          More info
        </MenuItem>
        <MenuItem
          component={Link}
          to={createPath({
            type: "edit",
            resource,
            id: record.id,
          })}
        >
          Edit
        </MenuItem>
      </Menu>
    </div>
  );
};

const SurveyListAsideCard = (props) => {
  const theme = useTheme();
  return (
    <Card sx={{ mt: "8px", mb: "3em", ml: "1em", width: asideWidth }}>
      <CardHeader
        action={props.action}
        sx={{
          backgroundColor: theme.palette.yellow.main,
          py: props.action ? "3px" : "7px",
        }}
        title={props.title}
        titleTypographyProps={{
          variant: "span",
          fontWeight: "bold",
        }}
      />
      <CardContent
        sx={{
          "& .RaLabeled-label": { fontWeight: "bold" },
          "& fieldset": {
            borderColor: theme.palette.yellow.main,
            borderRadius: "12px",
          },
          "& legend": {
            fontSize: "12px",
            fontWeight: "bold",
          },
        }}
      >
        {props.children || <Stack spacing={1} />}
      </CardContent>
    </Card>
  );
};

const SurveyListAside = (props) => {
  const { data, isPending, selectedIds } = useListContext();
  if (isPending) return null;
  if (!data) return null;
  if (!data.length) return null;
  const record = data?.find((e) => e.id === props.selection);
  if (!record)
    return (
      <SurveyListAsideCard
        title={`${selectedIds.length} ${inflect("survey", selectedIds.length)} selected`}
      />
    );
  if (!props.selection)
    return <SurveyListAsideCard title="No survey selected" />;
  return (
    <RecordContextProvider value={record}>
      <SurveyListAsideCard action={<AsideOptions />} title={record.name}>
        <Stack spacing={1}>
          {record.start_date && (
            <Box component="fieldset">
              <legend>Start date</legend>
              <DateField source="start_date" showTime />
            </Box>
          )}
          {record.end_date && (
            <Box component="fieldset">
              <legend>End date</legend>
              <DateField source="end_date" showTime />
            </Box>
          )}
          {record.description && (
            <Box component="fieldset">
              <legend>Description</legend>
              <TextField source="description" />
            </Box>
          )}
        </Stack>
      </SurveyListAsideCard>
    </RecordContextProvider>
  );
};

const exporter = (surveys, fetchRelatedRecords) => {
  const surveysForExport = surveys.map((survey) => {
    const { name, start_date, end_date, is_active } = survey;
    let startDate = new Date(start_date) || null;
    startDate = startDate.valueOf() ? startDate : null;
    startDate = startDate?.toLocaleString();
    let endDate = new Date(end_date) || null;
    endDate = endDate.valueOf() ? endDate : null;
    endDate = endDate?.toLocaleString();
    return {
      name,
      start_date: startDate,
      end_date: endDate,
      is_active: is_active ? "Active" : "Inactive",
    };
  });
  jsonExport(
    surveysForExport,
    {
      headers: ["name", "start_date", "end_date", "is_active"],
      rename: ["Name", "Start date", "End date", "Status"],
    },
    (err, csv) => {
      downloadCSV(csv, "surveys"); // download as 'surveys.csv` file
    },
  );
};

export const SurveyList = (props) => {
  const [selection, setSelection] = useStore(
    props.selectionStore ?? "survey.list",
    null,
  );
  const theme = useTheme();

  return (
    <List
      {...props}
      actions={
        <TopToolbar>
          <ResetSortButton field="created_at" order="DESC" />
          <CreateButton />
          <ExportButton />
        </TopToolbar>
      }
      aside={<SurveyListAside selection={selection} />}
      exporter={exporter}
      filters={[<SearchInput key="search" source="search" alwaysOn />]}
      sort={{ field: "created_at", order: "DESC" }}
      sx={{ m: 2 }}
    >
      <Datagrid
        bulkActionsToolbar={
          <BulkActionsToolbar label="1 survey selected |||| %{smart_count} surveys selected">
            <BulkExportButton color="white" />
            <BulkDeleteButton
              mutationMode="pessimistic"
              color="white"
              deleteSuccessMessage="Survey deleted |||| %{smart_count} surveys deleted"
            />
          </BulkActionsToolbar>
        }
        rowClick={(id, resource, record) => {
          setSelection(id);
          return false;
        }}
        rowSx={(record, index) => ({
          backgroundColor:
            record.id === selection ? theme.palette.lightYellow.main : null,
        })}
      >
        <TextField source="name" />
        {/*TODO: Number of respondents*/}
        {/*<ReferenceManyCount
          label="Respondents"
          reference="users"
          target="surveys"
        />*/}
        <DateField source="start_date" showTime />
        <DateField source="end_date" showTime />
        <FunctionField
          source="is_active"
          label="Status"
          render={(record) => (record.is_active ? "Active" : "Inactive")}
        />
      </Datagrid>
    </List>
  );
};

const SurveyShowData = (props) => {
  const theme = useTheme();
  const { record } = useShowContext();

  if (!record) return null;
  return (
    <Stack direction="row">
      <Card sx={{ flexGrow: 1, m: 2 }}>
        <CardContent
          sx={{
            "& .RaLabeled-label": { fontWeight: "bold" },
            "& fieldset": {
              borderColor: theme.palette.yellow.main,
              borderRadius: "12px",
              m: 1,
            },
            "& legend": {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {record.name && (
              <Box component="fieldset">
                <legend>Name</legend>
                <TextField source="name" />
              </Box>
            )}
            {record.start_date && (
              <Box component="fieldset">
                <legend>Start date</legend>
                <DateField source="start_date" showTime />
              </Box>
            )}
            {record.end_date && (
              <Box component="fieldset">
                <legend>End date</legend>
                <DateField source="end_date" showTime />
              </Box>
            )}
            {record.description && (
              <Box component="fieldset">
                <legend>Description</legend>
                <TextField source="description" />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ ml: 2, width: asideWidth }} />
    </Stack>
  );
};

const documentStyles = StyleSheet.create({
  page: {},
  pageNumber: {
    fontSize: 12,
    bottom: 0,
    left: 0,
    right: 0,
    width: "100%",
    textAlign: "center",
    padding: 10,
    position: "absolute",
  },
  row: {
    flexDirection: "row",
  },
  section: {
    margin: 10,
    padding: 10,
  },
  h1: {
    fontSize: 34,
    fontStyle: "bold",
  },
  h2: {
    fontSize: 30,
    fontStyle: "bold",
  },
  h3: {
    fontSize: 24,
    fontStyle: "bold",
  },
  h4: {
    fontSize: 20,
    fontStyle: "bold",
  },
  h5: {
    fontSize: 18,
    fontStyle: "bold",
  },
  h6: {
    fontSize: 16,
    fontStyle: "bold",
  },
  subtitle: {
    fontSize: 12,
    marginBottom: 10,
  },
  tableRow: {
    fontSize: 11,
    width: "100%",
    padding: 6,
  },
  tableRowOdd: {
    fontSize: 11,
    width: "100%",
    backgroundColor: "#DDDDDD",
    padding: 6,
  },
});

const responseCount = (n) => `${n} ${inflect("valid response", n)}`;

const SurveyResultsDocument = ({ record, questions }) => (
  <Document title={`${record.name} - Survey results`} author="Pic-A-Talk">
    <Page size="A4" style={documentStyles.page}>
      <Text
        style={documentStyles.pageNumber}
        render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
        fixed
      />
      <View style={documentStyles.section}>
        <Text style={documentStyles.h1}>{record.name}</Text>
        <Text>Survey results</Text>
      </View>
      {questions.map((question) => (
        <View key={question.id} style={documentStyles.section} wrap={false}>
          <Text>{question.name}</Text>
          <Text style={documentStyles.subtitle}>
            {question.type === "Rating" &&
              responseCount(
                question.results.reduce((n, result) => n + result.count, 0),
              )}
            {question.type === "Choice" &&
              responseCount(
                question.results
                  .filter((row) => row.answer.trim())
                  .reduce((n, result) => n + result.count, 0),
              )}
            {question.type === "Text" &&
              responseCount(
                question.results.filter((row) => row.answer.trim()).length,
              )}
          </Text>
          {question.type === "Rating" && question.results.length && (
            <ReactPDFChart>
              <QuestionChart
                isAnimationActive={false}
                record={question}
                results={question.results}
                type={question.type}
                height={220}
                width={450}
              />
            </ReactPDFChart>
          )}
          {question.type === "Choice" && question.results.length && (
            <ReactPDFChart>
              <QuestionChart
                isAnimationActive={false}
                record={question}
                results={question.results}
                type={question.type}
                height={Math.max(220, question.results.length * 48)}
                width={450}
              />
            </ReactPDFChart>
          )}
          {question.type === "Text" &&
            question.results
              .filter((row) => row.answer.trim())
              .map((row, i) => (
                <Text
                  key={row.id}
                  style={
                    i % 2 ? documentStyles.tableRow : documentStyles.tableRowOdd
                  }
                >
                  {row.answer}
                </Text>
              ))}
        </View>
      ))}
    </Page>
  </Document>
);

/* Only for development/debugging purposes */
// eslint-disable-next-line
const SurveyResultsDocumentPreview = ({ record }) => {
  const dataProvider = useDataProvider();
  const { data, isPending, error } = useQuery({
    enabled: record.id !== undefined,
    queryKey: ["surveys", "getSurveyResults", { id: record.id }],
    queryFn: ({ signal }) => dataProvider.getSurveyResults(record.id),
  });

  if (isPending) return null;
  if (!record) return null;
  if (error)
    return (
      <Stack direction="row">
        <Box textAlign="center" mx="auto">
          <Typography variant="h4" paragraph>
            Something went wrong
          </Typography>
        </Box>
        <Box sx={{ ml: 2, width: asideWidth }} />
      </Stack>
    );
  if (!data) return null;
  console.log(data);
  return (
    <PDFViewer width="1000" height="650">
      <SurveyResultsDocument record={record} questions={data.data} />
    </PDFViewer>
  );
};

const SurveyResultsDownloadButton = ({ record }) => {
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const { data, isPending, error } = useQuery({
    enabled: record.id !== undefined,
    queryKey: ["surveyResults", "getSurveyResults", { id: record.id }],
    queryFn: ({ signal }) => dataProvider.getSurveyResults(record.id),
  });

  if (isPending) return null;
  if (!record) return null;
  if (!data) return null;
  return (
    <RaButton
      disabled={error}
      label="Download PDF"
      onClick={async () => {
        try {
          const fileName = `results_${record.name}.pdf`;
          const blob = await pdf(
            <SurveyResultsDocument record={record} questions={data.data} />,
          ).toBlob();
          saveAs(blob, fileName);
        } catch (error) {
          console.error(error);
          notify("Could not downloads PDF for survey results.", {
            type: "error",
          });
        }
      }}
    >
      <PictureAsPdfIcon />
    </RaButton>
  );
};

const SurveyQuestions = (props) => {
  const { record } = useShowContext();
  if (!record) return null;
  return (
    <QuestionList
      actions={
        <TopToolbar>
          <ResetSortButton field="created_at" order="DESC" />
          <CreateButton
            resource="questions"
            state={{ record: { survey: record.id } }}
          />
          <ExportButton />
          <SurveyResultsDownloadButton record={record} />
        </TopToolbar>
      }
      disableSyncWithLocation
      resource="questions"
      title=" "
      filter={{ survey: record.id }}
      selectionStore={`survey.questions.selection.${record.id}`}
      storeKey={`survey.questions.${record.id}`}
      empty={
        <Stack direction="row">
          <Box textAlign="center" mx="auto">
            <Typography variant="h4" paragraph>
              No questions in this survey
            </Typography>
            <CreateButton
              resource="questions"
              state={{ record: { survey: record.id } }}
            />
          </Box>
          <Box sx={{ ml: 2, width: asideWidth }} />
        </Stack>
      }
    />
  );
};

// const SurveyResults = (props) => {
//   const { record } = useShowContext();
//   if (!record) return null;
//   return (
//     <List
//       {...props}
//       actions={null}
//       aside={<Box sx={{ ml: 2, width: asideWidth }} />}
//       disableSyncWithLocation
//       filter={{ survey: record.id }}
//       resource="questions"
//       sort={{ field: "created_at", order: "ASC" }}
//       sx={{
//         m: 2,
//         "& .RaList-content": {
//           py: 4,
//         },
//       }}
//       title=" "
//     >
//       <WithListContext
//         render={({ isPending, data }) =>
//           !isPending &&
//           data && (
//             <Stack spacing={2}>
//               {data.map((result) => (
//                 <Stack key={result.id}>
//                   <Typography variant="h6" sx={{ mx: 2 }}>
//                     {result.name}
//                   </Typography>
//                   <QuestionResults record={result} />
//                 </Stack>
//               ))}
//             </Stack>
//           )
//         }
//       />
//     </List>
//   );
// };

export const SurveyShow = (props) => {
  const createPath = useCreatePath();
  return (
    <Show
      actions={
        <TopToolbar>
          <WithRecord
            render={(record) => (
              <EditButton
                state={{
                  redirectPath: createPath({
                    resource: "surveys",
                    type: "show",
                    id: record.id,
                  }),
                }}
              />
            )}
          />
          <Box sx={{ ml: 2, width: asideWidth }} />
        </TopToolbar>
      }
      component={Box}
      title="Surveys"
      {...props}
    >
      <BackButton />
      <Box sx={{ py: 2 }} />
      <Typography variant="h5" sx={{ mx: 2 }}>
        Info
      </Typography>
      <SurveyShowData />
      <Typography variant="h5" sx={{ mx: 2 }}>
        Questions
      </Typography>
      <SurveyQuestions />
      {/*<Typography variant="h5" sx={{ mx: 2 }}>
        Results
      </Typography>
      <SurveyResults />*/}
      {/*<WithRecord
        render={(record) => {
          console.log(record);
          return <SurveyResultsDocumentPreview record={record} />;
        }}
      />*/}
    </Show>
  );
};

const FormToolbar = (props) => (
  <Toolbar sx={{ justifyContent: "right" }}>
    <SaveButton
      color="black"
      sx={{ textTransform: "none" }}
      icon={<SaveOutlinedIcon />}
    />
  </Toolbar>
);

const BackButton = (props) => {
  const resource = useResourceContext(props);
  const createPath = useCreatePath();
  return (
    <Button
      component={Link}
      to={props.destination || createPath({ type: "list", resource })}
      color="yellow"
      variant="contained"
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        fontWeight: "bold",
        textTransform: "none",
      }}
      startIcon={<ArrowBackIcon />}
    >
      Back
    </Button>
  );
};

const validateForm = (values) => {
  const errors = {};
  if (values.start_date && values.end_date) {
    // In some cases, start_date and end_date are presented under different types
    // and either can be invalid for submission or resubmission.
    const startDate = new Date(values.start_date);
    const endDate = new Date(values.end_date);
    if (startDate >= endDate) {
      errors.start_date = "Start date must be earlier than End date";
    }
  }
  return errors;
};

const transform = (data) => {
  const transformedData = { ...data };
  if (data.end_date) {
    // In some cases, start_date and end_date are presented under different types
    // and either can be invalid for submission or resubmission.
    const endDate = new Date(data.end_date);
    if (endDate < new Date()) {
      transformedData.is_active = false;
    }
  }
  return transformedData;
};

const SurveyForm = (props) => {
  return (
    <SimpleForm
      sx={{ px: 3 }}
      toolbar={<FormToolbar />}
      validate={validateForm}
    >
      <TextInput source="name" fullWidth validate={[required()]} />
      <TextInput source="description" fullWidth multiline />
      <DateTimeInput
        source="start_date"
        fullWidth
        inputProps={{
          min: "0001-01-01T00:00",
          max: "9999-12-31T00:00",
        }}
      />
      <DateTimeInput
        source="end_date"
        fullWidth
        inputProps={{
          min: "0001-01-01T00:00",
          max: "9999-12-31T00:00",
        }}
      />
      <BooleanInput source="is_active" defaultValue={false} />
    </SimpleForm>
  );
};

export const SurveyCreate = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify("Survey created.");
    redirect("list", "surveys");
  };

  const onError = (error) => {
    notify(error.message || "Could not create survey.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton />
      <Container maxWidth="sm">
        <Create
          title="New Survey"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          transform={transform}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Survey"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <SurveyForm />
          </Card>
        </Create>
      </Container>
    </Container>
  );
};

export const SurveyEdit = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();
  const { state } = useLocation();
  const navigate = useNavigate();

  const onSuccess = (data) => {
    notify("Survey updated.");
    if (state?.redirectPath) {
      navigate(state?.redirectPath);
    } else {
      redirect("list", "surveys");
    }
  };

  const onError = (error) => {
    notify(error.message || "Could not update survey.", { type: "error" });
  };

  return (
    <Container maxWidth={false} sx={{ my: { xs: 3 }, px: { xs: 0 } }}>
      <BackButton destination={state?.redirectPath} />
      <Container maxWidth="sm" sx={{}}>
        <Edit
          title="Edit Survey"
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          transform={transform}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Survey"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <SurveyForm />
          </Card>
        </Edit>
      </Container>
    </Container>
  );
};
