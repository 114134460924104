import { useState, useEffect, useCallback } from "react";
import {
  required,
  SimpleForm,
  TextInput,
  useLogin,
  useNotify,
  useSafeSetState,
  useTranslate,
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Container,
  IconButton,
  InputAdornment,
  Link,
} from "@mui/material";
import {
  EmailOutlined,
  PasswordOutlined,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import "./css/styles.css";
import logo from "../images/picatalk_logo.png";

function Login() {
  const [loading, setLoading] = useSafeSetState(false);
  const login = useLogin();
  const notify = useNotify();
  const navigate = useNavigate();

  const validateToken = useCallback(
    async (token) => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/admin-api/users`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );

        if (response.ok) {
          // Redirect to dashboard if the token is still valid
          navigate("/", { replace: true });
        } else {
          localStorage.removeItem("token");
          navigate("/login", { replace: true });
        }
      } catch (error) {
        localStorage.removeItem("token");
        navigate("/login", { replace: true });
      }
    },
    [navigate],
  );

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      validateToken(token);
    }
  }, [validateToken]);

  const handleLogin = (values) => {
    setLoading(true);
    login(values)
      .then(() => {
        setLoading(false);
        navigate("/", { replace: true });
      })
      .catch((error) => {
        setLoading(false);
        notify(
          typeof error === "string"
            ? error
            : typeof error === "undefined" || !error.message
              ? "ra.auth.sign_in_error"
              : error.message,
          {
            type: "error",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error?.message
                    ? error.message
                    : undefined,
            },
          },
        );
      });
  };

  return (
    <Box
      sx={{
        // TODO: Move this color to global theme
        bgcolor: "#FFDE59",
        display: "flex",
        flexDirection: "column",
        minHeight: "100vh",
        height: "1px",
        alignItems: "center",
        justifyContent: "flex-start",
      }}
    >
      <Container
        sx={{
          height: "100%",
          pt: "5rem",
        }}
      >
        <Card
          sx={{
            borderRadius: "3rem 3rem 0 0",
            height: "100%",
            overflow: "visible",
            textAlign: "center",
          }}
        >
          <Box
            component="img"
            sx={{
              width: 128,
              height: 128,
              mt: -10,
              mx: "auto",
              textAlign: "center",
            }}
            alt="Pic-A-Talk Logo"
            src={logo}
          />
          <CardHeader
            title="Login"
            subheader="Login your account"
            titleTypographyProps={{
              color: "#030C68",
              fontWeight: 500,
              fontSize: 28,
            }}
            subheaderTypographyProps={{
              fontSize: 14,
            }}
          />
          <CardContent
            sx={{
              maxWidth: 800,
              mx: "auto",
            }}
          >
            <SimpleForm onSubmit={handleLogin} toolbar={false}>
              <TextInput
                label="Enter your email address"
                source="email"
                fullWidth
                validate={required("Email cannot be blank")}
                type="email"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EmailOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              <PasswordInput
                label="Enter your password"
                source="password"
                fullWidth
                validate={required("Password cannot be blank")}
              />
              <Container sx={{ mb: 3, textAlign: "right" }}>
                <Link
                  href="https://pic-a-talk-web.fly.dev/reset"
                  underline="none"
                >
                  Forgot your password?
                </Link>
              </Container>
              <Button
                variant="contained"
                type="submit"
                color="yellow"
                disabled={loading}
                sx={{
                  fontSize: 21,
                  mt: 3,
                  mx: "auto",
                  textTransform: "none",
                  width: "75%",
                }}
              >
                {loading ? (
                  <CircularProgress size={38} thickness={3} />
                ) : (
                  "Login"
                )}
              </Button>
            </SimpleForm>
          </CardContent>
        </Card>
      </Container>
    </Box>
  );
}

// Recreate the PasswordInput from
// https://github.com/marmelab/react-admin/blob/master/packages/ra-ui-materialui/src/input/PasswordInput.tsx
// because we can't insert the start adornment without losing the end adornment
// otherwise.
const PasswordInput = (props) => {
  const { initiallyVisible = false, ...rest } = props;
  const [visible, setVisible] = useState(initiallyVisible);
  const translate = useTranslate();

  const handleClick = () => {
    setVisible(!visible);
  };

  return (
    <TextInput
      type={visible ? "text" : "password"}
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <PasswordOutlined />
          </InputAdornment>
        ),
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label={translate(
                visible
                  ? "ra.input.password.toggle_visible"
                  : "ra.input.password.toggle_hidden",
              )}
              onClick={handleClick}
              size="large"
            >
              {visible ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...rest}
    />
  );
};

export default Login;
