import { useCallback } from "react";
import { IconButton } from "@mui/material";
import DownloadOutlinedIcon from "@mui/icons-material/DownloadOutlined";
import {
  fetchRelatedRecords,
  useDataProvider,
  useNotify,
  useListContext,
} from "react-admin";

export const BulkExportButton = (props) => {
  const {
    onClick,
    icon = defaultIcon,
    exporter: customExporter,
    meta,
    ...rest
  } = props;
  const {
    exporter: exporterFromContext,
    resource,
    selectedIds,
  } = useListContext(props);
  const exporter = customExporter || exporterFromContext;
  const dataProvider = useDataProvider();
  const notify = useNotify();
  const handleClick = useCallback(
    (event) => {
      exporter &&
        dataProvider
          .getMany(resource, { ids: selectedIds, meta })
          .then(({ data }) =>
            exporter(
              data,
              fetchRelatedRecords(dataProvider),
              dataProvider,
              resource,
            ),
          )
          .catch((error) => {
            console.error(error);
            notify("ra.notification.http_error", {
              type: "error",
            });
          });
      if (typeof onClick === "function") {
        onClick(event);
      }
    },
    [dataProvider, exporter, notify, onClick, resource, selectedIds, meta],
  );

  return (
    <IconButton onClick={handleClick} {...sanitizeRestProps(rest)}>
      {icon}
    </IconButton>
  );
};

const defaultIcon = <DownloadOutlinedIcon />;

const sanitizeRestProps = ({ filterValues, selectedIds, resource, ...rest }) =>
  rest;
