import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { humanize, inflect } from "inflection";
import { IconButton } from "@mui/material";
import {
  useDeleteMany,
  useListContext,
  useNotify,
  useRefresh,
  useResourceContext,
  useSafeSetState,
  useTranslate,
} from "react-admin";

import { Confirm } from "./Confirm";

export const BulkDeleteButton = (props) => {
  const {
    confirmTitle = "ra.message.bulk_delete_title",
    // confirmContent = "ra.message.bulk_delete_content",
    confirmContent = "Are you sure you want to delete this %{name}? |||| Are you sure you want to delete these %{smart_count} %{name}?",
    confirmColor = "primary",
    deleteSuccessMessage = "ra.notification.deleted",
    icon = defaultIcon,
    mutationMode = "pessimistic",
    mutationOptions = {},
    onClick,
    ...rest
  } = props;
  const { meta: mutationMeta, ...otherMutationOptions } = mutationOptions;
  const { selectedIds, onUnselectItems } = useListContext(props);
  const [isOpen, setOpen] = useSafeSetState(false);
  const notify = useNotify();
  const resource = useResourceContext(props);
  const refresh = useRefresh();
  const translate = useTranslate();
  const [deleteMany, { isLoading }] = useDeleteMany(
    resource,
    { ids: selectedIds, meta: mutationMeta },
    {
      onSuccess: () => {
        refresh();
        notify(deleteSuccessMessage, {
          type: "info",
          messageArgs: { smart_count: selectedIds.length },
          undoable: mutationMode === "undoable",
        });
        onUnselectItems();
        setOpen(false);
      },
      onError: (error) => {
        notify(
          typeof error === "string"
            ? error
            : error.message || "ra.notification.http_error",
          {
            type: "error",
            messageArgs: {
              _:
                typeof error === "string"
                  ? error
                  : error?.message
                    ? error.message
                    : undefined,
            },
          },
        );
        setOpen(false);
      },
      mutationMode,
      ...otherMutationOptions,
    },
  );

  const handleClick = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const handleDialogClose = () => {
    setOpen(false);
  };

  const handleDelete = (e) => {
    deleteMany();

    if (typeof onClick === "function") {
      onClick(e);
    }
  };

  return (
    <>
      <IconButton onClick={handleClick} {...sanitizeRestProps(rest)}>
        {icon}
      </IconButton>
      <Confirm
        isOpen={isOpen}
        loading={isLoading}
        title={confirmTitle}
        content={confirmContent}
        confirmColor={confirmColor}
        translateOptions={{
          smart_count: selectedIds.length,
          name: translate(`resources.${resource}.forcedCaseName`, {
            smart_count: selectedIds.length,
            _: humanize(
              translate(`resources.${resource}.name`, {
                smart_count: selectedIds.length,
                _: inflect(resource, selectedIds.length),
              }),
              true,
            ),
          }),
        }}
        onConfirm={handleDelete}
        onClose={handleDialogClose}
      />
    </>
  );
};

const sanitizeRestProps = ({ classes, filterValues, selectedIds, ...rest }) =>
  rest;

const defaultIcon = <DeleteOutlineIcon />;
