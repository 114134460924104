import { lazy, Suspense } from "react";
import { Admin, Resource } from "react-admin";
import { Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import { darkTheme, lightTheme } from "./theme";
import AuthPage from "./components/authPage";
import Layout from "./components/Layout";
import { ChildCreate, ChildEdit } from "./components/children";
// import {
//   AnswerCreate,
//   AnswerEdit,
// } from "./components/answers";
import {
  GroupCreate,
  GroupEdit,
  GroupList,
  GroupShow,
} from "./components/groups";
import {
  OrganizationCreate,
  OrganizationEdit,
  OrganizationList,
  OrganizationShow,
} from "./components/organizations";
import {
  ReferralCodeCreate,
  ReferralCodeEdit,
  ReferralCodeList,
} from "./components/referralCodes";
import {
  SurveyCreate,
  SurveyEdit,
  SurveyList,
  SurveyShow,
} from "./components/surveys";
import {
  QuestionCreate,
  QuestionEdit,
  QuestionShow,
} from "./components/questions";
import {
  QuestionChoiceCreate,
  QuestionChoiceEdit,
} from "./components/questionChoices";
import authProvider from "./provider/authProvider";
import dataProvider from "./provider/dataProvider";
import { UserCreate, UserEdit, UserList } from "./components/users";

const ViewChildDetails = lazy(() => import("./components/viewChild"));
const ChangePassword = lazy(() => import("./components/changePassword"));
const Dashboard = lazy(() => import("./components/Dashboard"));

const App = () => (
  <ThemeProvider theme={darkTheme}>
    <Admin
      title="Pic-A-Talk CMS"
      dataProvider={dataProvider}
      dashboard={Dashboard}
      authProvider={authProvider}
      layout={Layout}
      loginPage={AuthPage}
      theme={lightTheme}
      // darkTheme={darkTheme}
    >
      {(permissions) => (
        <>
          <Resource
            name="users"
            icon={() => null}
            list={UserList}
            create={permissions.canCreateUser ? UserCreate : null}
            edit={permissions.canEdit ? UserEdit : null}
            recordRepresentation={(record) =>
              `${record.first_name ?? ""} ${record.last_name ?? ""}`
            }
          >
            {permissions.canEdit ? (
              <Route path=":id/change-password" element={<ChangePassword />} />
            ) : null}
            <Route
              path=":id/child"
              element={
                <Suspense fallback={<div>Loading...</div>}>
                  <ViewChildDetails />
                </Suspense>
              }
              noLayout
            />
          </Resource>
          <Resource
            name="referral-codes"
            icon={() => null}
            list={ReferralCodeList}
            create={ReferralCodeCreate}
            edit={ReferralCodeEdit}
            options={{ label: "Referral codes" }}
            recordRepresentation={(record) => record.code}
          />
          <Resource
            name="children"
            icon={() => null}
            create={permissions.canCreateUser ? ChildCreate : null}
            edit={permissions.canEdit ? ChildEdit : null}
            recordRepresentation={(record) =>
              `${record.first_name ?? ""} ${record.last_name ?? ""}`
            }
          />
          {permissions.canCreateUser ? (
            <Resource
              name="organizations"
              icon={() => null}
              list={OrganizationList}
              create={OrganizationCreate}
              edit={OrganizationEdit}
              show={OrganizationShow}
              recordRepresentation={(record) => record.name}
            />
          ) : null}
          {permissions.canCreateUser ? (
            <Resource
              name="groups"
              icon={() => null}
              list={GroupList}
              create={GroupCreate}
              edit={GroupEdit}
              show={GroupShow}
              recordRepresentation={(record) => record.name}
            />
          ) : null}
          {permissions.canCreateUser ? (
            <Resource
              name="surveys"
              icon={() => null}
              list={SurveyList}
              create={SurveyCreate}
              edit={SurveyEdit}
              show={SurveyShow}
              recordRepresentation={(record) => record.name}
            />
          ) : null}
          {permissions.canCreateUser ? (
            <Resource
              name="questions"
              icon={() => null}
              create={QuestionCreate}
              edit={QuestionEdit}
              show={QuestionShow}
              recordRepresentation={(record) => record.name}
            />
          ) : null}
          {permissions.canCreateUser ? (
            <Resource
              name="question-choices"
              icon={() => null}
              create={QuestionChoiceCreate}
              edit={QuestionChoiceEdit}
              recordRepresentation={(record) => record.value}
            />
          ) : null}
          {permissions.canCreateUser ? (
            <Resource
              name="answers"
              icon={() => null}
              // create={AnswerCreate}
              // edit={AnswerEdit}
            />
          ) : null}
        </>
      )}
    </Admin>
  </ThemeProvider>
);

export default App;
