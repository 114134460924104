import { useState } from "react";
import { Link } from "react-router-dom";
import { useMutation } from "@tanstack/react-query";
import {
  BulkActionsToolbar,
  Create,
  Datagrid,
  downloadCSV,
  Edit,
  EditButton,
  ExportButton,
  FunctionField,
  List,
  RecordContextProvider,
  ReferenceManyCount,
  SaveButton,
  Show,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  TopToolbar,
  useCreatePath,
  useDataProvider,
  useListContext,
  useNotify,
  useRecordContext,
  useRedirect,
  useRefresh,
  useResourceContext,
  useShowContext,
  useStore,
  WithRecord,
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import RemoveIcon from "@mui/icons-material/Remove";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { inflect } from "inflection";
import jsonExport from "jsonexport/dist";

import { BulkDeleteButton } from "./BulkDeleteButton";
import { BulkExportButton } from "./BulkExportButton";
import ResetSortButton from "./ResetSortButton";
import { Confirm } from "./Confirm";
import { UserList } from "./users";

const asideWidth = "400px";

const AsideOptions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const createPath = useCreatePath();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        aria-label="more"
        id="group-aside-button"
        aria-controls={open ? "group-aside-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="black"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="group-aside-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          component={Link}
          to={createPath({
            type: "show",
            resource,
            id: record.id,
          })}
        >
          Show users
        </MenuItem>
        <MenuItem
          component={Link}
          to={createPath({
            type: "edit",
            resource,
            id: record.id,
          })}
        >
          Edit
        </MenuItem>
      </Menu>
    </div>
  );
};

const GroupListAsideCard = (props) => {
  const theme = useTheme();
  return (
    <Card sx={{ mt: "8px", mb: "3em", ml: "1em", width: asideWidth }}>
      <CardHeader
        action={props.action}
        sx={{
          backgroundColor: theme.palette.yellow.main,
          py: props.action ? "3px" : "7px",
        }}
        title={props.title}
        titleTypographyProps={{
          variant: "span",
          fontWeight: "bold",
        }}
      />
      <CardContent
        sx={{
          "& .RaLabeled-label": { fontWeight: "bold" },
          "& fieldset": {
            borderColor: theme.palette.yellow.main,
            borderRadius: "12px",
          },
          "& legend": {
            fontSize: "12px",
            fontWeight: "bold",
          },
        }}
      >
        {props.children || <Stack spacing={1} />}
      </CardContent>
    </Card>
  );
};

const GroupListAside = (props) => {
  const { data, isPending, selectedIds } = useListContext();
  if (isPending) return null;
  if (!data) return null;
  if (!data.length) return null;
  const record = data?.find((e) => e.id === props.selection);
  if (!record)
    return (
      <GroupListAsideCard
        title={`${selectedIds.length} ${inflect("group", selectedIds.length)} selected`}
      />
    );
  if (!props.selection) return <GroupListAsideCard title="No group selected" />;
  return (
    <RecordContextProvider value={record}>
      <GroupListAsideCard action={<AsideOptions />} title={record.name} />
    </RecordContextProvider>
  );
};

const exporter = async (groups, fetchRelatedRecords) => {
  // const users = await fetchRelatedRecords(groups, "users", "groups");
  // const users = await fetchRelatedRecords(groups, "groups", "users");
  // console.log(users);
  const groupsForExport = groups.map((group) => {
    const { name } = group;
    return {
      name,
      // member_count: users.filter((user) => user.groups.contains(group.id))
      //   .length,
    };
  });
  jsonExport(
    groupsForExport,
    {
      headers: ["name"],
      rename: ["Name"],
    },
    (err, csv) => {
      downloadCSV(csv, "groups"); // download as 'groups.csv` file
    },
  );
};

export const GroupList = (props) => {
  const [selection, setSelection] = useStore(
    props.selectionStore ?? "group.list",
    null,
  );
  const theme = useTheme();

  return (
    <List
      {...props}
      aside={<GroupListAside selection={selection} />}
      exporter={exporter}
      sx={{ m: 2 }}
    >
      <Datagrid
        bulkActionsToolbar={
          <BulkActionsToolbar label="1 group selected |||| %{smart_count} groups selected">
            <BulkExportButton color="white" />
            <BulkDeleteButton
              mutationMode="pessimistic"
              color="white"
              deleteSuccessMessage="Group deleted |||| %{smart_count} groups deleted"
            />
          </BulkActionsToolbar>
        }
        rowClick={(id, resource, record) => {
          setSelection(id);
          return false;
        }}
        rowSx={(record, index) => ({
          backgroundColor:
            record.id === selection ? theme.palette.lightYellow.main : null,
        })}
      >
        <TextField source="name" />
        <ReferenceManyCount
          label="Member count"
          reference="users"
          target="groups"
        />
      </Datagrid>
    </List>
  );
};

const GroupShowData = (props) => {
  const theme = useTheme();
  const { record } = useShowContext();
  if (!record) return null;
  return (
    <Stack direction="row">
      <Card sx={{ flexGrow: 1, m: 2 }}>
        <CardContent
          sx={{
            "& .RaLabeled-label": { fontWeight: "bold" },
            "& fieldset": {
              borderColor: theme.palette.yellow.main,
              borderRadius: "12px",
              m: 1,
            },
            "& legend": {
              fontSize: "12px",
              fontWeight: "bold",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {record.name && (
              <Box component="fieldset">
                <legend>Name</legend>
                <TextField source="name" />
              </Box>
            )}
          </Box>
        </CardContent>
      </Card>
      <Box sx={{ ml: 2, width: asideWidth }} />
    </Stack>
  );
};

const GroupShowAddUserField = (props) => {
  const { record } = useShowContext();
  const notify = useNotify();
  const refresh = useRefresh();
  const userRecord = useRecordContext();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      dataProvider.addUserToGroup(userRecord.id, {
        data: { id: record.id },
      }),
    onSuccess: () => {
      refresh();
      notify(
        `Added ${userRecord.first_name ?? ""} ${userRecord.last_name ?? ""}`,
      );
    },
    onError: () => {
      refresh();
      notify("Failed to add user");
    },
  });
  const handleAdd = (e) => {
    e.stopPropagation();
    mutate();
  };

  return (
    <FunctionField
      render={(user) => (
        <IconButton
          aria-label="Add"
          color="black"
          size="small"
          onClick={handleAdd}
          disabled={isLoading}
          sx={{ p: 0 }}
        >
          <AddIcon />
        </IconButton>
      )}
    />
  );
};

const GroupShowRemoveUserField = (props) => {
  const { record } = useShowContext();
  const [open, setOpen] = useState(false);
  const notify = useNotify();
  const refresh = useRefresh();
  const userRecord = useRecordContext();
  const dataProvider = useDataProvider();
  const { mutate, isLoading } = useMutation({
    mutationFn: () =>
      dataProvider.removeUserFromGroup(userRecord.id, {
        data: { id: record.id },
      }),
    onSuccess: () => {
      notify(
        `Removed ${userRecord.first_name ?? ""} ${userRecord.last_name ?? ""}`,
      );
      refresh();
    },
    onError: () => {
      notify("Failed to remove user");
      refresh();
    },
  });

  const handleClick = (e) => {
    e.stopPropagation();
    setOpen(true);
  };
  const handleDialogClose = () => setOpen(false);
  const handleConfirm = () => {
    mutate();
    setOpen(false);
  };

  return (
    <FunctionField
      render={(user) => (
        <>
          <IconButton
            aria-label="Remove"
            color="black"
            size="small"
            onClick={handleClick}
            disabled={isLoading}
            sx={{ p: 0 }}
          >
            <RemoveIcon />
          </IconButton>
          <Confirm
            isOpen={open}
            loading={isLoading}
            title={"Remove user from group"}
            content={`Are you sure you want to remove ${userRecord && `${userRecord.first_name ?? ""} ${userRecord.last_name ?? ""}`} from ${record?.name}?`}
            onConfirm={handleConfirm}
            onClose={handleDialogClose}
          />
        </>
      )}
    />
  );
};

const GroupShowMembers = (props) => {
  const { record } = useShowContext();
  const createPath = useCreatePath();
  const resource = useResourceContext();
  if (!record) return null;
  return (
    <UserList
      actions={
        <TopToolbar>
          <ResetSortButton field="created_at" order="DESC" />
          <ExportButton />
        </TopToolbar>
      }
      disableSyncWithLocation
      resource="users"
      title=" "
      filter={{ groups: record.id }}
      extraFields={<GroupShowRemoveUserField />}
      disableActions
      bulkActionButtons={<BulkExportButton color="white" />}
      selectionStore={`group.showMembers.selection.${record.id}`}
      storeKey={`group.showMembers.${record.id}`}
      redirectPath={createPath({
        type: "show",
        resource,
        id: record.id,
      })}
      empty={
        <Stack direction="row">
          <Box textAlign="center" mx="auto">
            <Typography variant="h4" paragraph>
              No members in this Group
            </Typography>
            <Typography variant="body1">
              Add members using the list below.
            </Typography>
          </Box>
          <Box sx={{ ml: 2, width: asideWidth }} />
        </Stack>
      }
    />
  );
};

const GroupShowAddUsers = (props) => {
  const { record } = useShowContext();
  const createPath = useCreatePath();
  const resource = useResourceContext();
  if (!record) return null;
  return (
    <UserList
      actions={
        <TopToolbar>
          <ResetSortButton field="created_at" order="DESC" />
          <ExportButton />
        </TopToolbar>
      }
      disableSyncWithLocation
      resource="users"
      title=" "
      filter={{ groups__ne: record.id }}
      extraFields={<GroupShowAddUserField />}
      disableActions
      bulkActionButtons={<BulkExportButton color="white" />}
      selectionStore={`group.addUsers.selection.${record.id}`}
      storeKey={`group.addUsers.${record.id}`}
      redirectPath={createPath({
        type: "show",
        resource,
        id: record.id,
      })}
    />
  );
};

export const GroupShow = (props) => {
  const createPath = useCreatePath();
  const resource = useResourceContext();
  return (
    <Show
      actions={
        <TopToolbar>
          <WithRecord
            render={(record) => (
              <EditButton
                state={{
                  redirectPath: createPath({
                    resource,
                    type: "show",
                    id: record.id,
                  }),
                }}
              />
            )}
          />
          <Box sx={{ ml: 2, width: asideWidth }} />
        </TopToolbar>
      }
      component={Box}
      {...props}
    >
      <BackButton />
      <Box sx={{ py: 2 }} />
      <Typography variant="h5" sx={{ mx: 2 }}>
        Info
      </Typography>
      <GroupShowData />
      <Typography variant="h5" sx={{ mx: 2 }}>
        Members
      </Typography>
      <GroupShowMembers />
      <Typography variant="h5" sx={{ mx: 2 }}>
        Add new members
      </Typography>
      <GroupShowAddUsers />
    </Show>
  );
};

const FormToolbar = (props) => (
  <Toolbar sx={{ justifyContent: "right" }}>
    <SaveButton
      color="black"
      sx={{ textTransform: "none" }}
      icon={<SaveOutlinedIcon />}
    />
  </Toolbar>
);

const BackButton = (props) => {
  const resource = useResourceContext(props);
  const createPath = useCreatePath();
  return (
    <Button
      component={Link}
      to={createPath({ type: "list", resource })}
      color="yellow"
      variant="contained"
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        fontWeight: "bold",
        textTransform: "none",
      }}
      startIcon={<ArrowBackIcon />}
    >
      Back
    </Button>
  );
};

const GroupForm = (props) => (
  <SimpleForm sx={{ px: 3 }} toolbar={<FormToolbar />}>
    <TextInput source="name" fullWidth required />
  </SimpleForm>
);

export const GroupCreate = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify("Group created.");
    redirect("list", "groups");
  };

  const onError = (error) => {
    notify("Could not create group.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton />
      <Container maxWidth="sm">
        <Create
          title="New Group"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Group"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <GroupForm />
          </Card>
        </Create>
      </Container>
    </Container>
  );
};

export const GroupEdit = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();

  const onSuccess = (data) => {
    notify("Group updated.");
    redirect("list", "groups");
  };

  const onError = (error) => {
    notify("Could not update group.", { type: "error" });
  };

  return (
    <Container maxWidth={false} sx={{ my: { xs: 3 }, px: { xs: 0 } }}>
      <BackButton />
      <Container maxWidth="sm" sx={{}}>
        <Edit
          title="Edit Group"
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Group"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <GroupForm />
          </Card>
        </Edit>
      </Container>
    </Container>
  );
};
