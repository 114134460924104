import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  BulkActionsToolbar,
  Create,
  Datagrid,
  downloadCSV,
  Edit,
  EditBase,
  FormDataConsumer,
  List,
  ListBase,
  RecordContextProvider,
  required,
  SaveButton,
  SearchInput,
  SimpleForm,
  TextField,
  TextInput,
  Toolbar,
  useCreatePath,
  useListContext,
  useNotify,
  useRecordContext,
  useRedirect,
  useResourceContext,
  useStore,
  useUnique,
  WithListContext,
  WithRecord,
} from "react-admin";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  IconButton,
  Menu,
  MenuItem,
  Stack,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SaveOutlinedIcon from "@mui/icons-material/SaveOutlined";

import { inflect } from "inflection";
import jsonExport from "jsonexport/dist";

import { BulkDeleteButton } from "./BulkDeleteButton";
import { BulkExportButton } from "./BulkExportButton";

const asideWidth = "400px";

const AsideOptions = (props) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const createPath = useCreatePath();
  const record = useRecordContext(props);
  const resource = useResourceContext(props);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ListBase
      resource="answers"
      filter={{ question: record.question, answer: record.value }}
    >
      <IconButton
        aria-label="more"
        id="question-choice-aside-button"
        aria-controls={open ? "question-choice-aside-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
        color="black"
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="question-choice-aside-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <WithListContext
          render={({ isPending, total }) =>
            !isPending && (
              <MenuItem
                component={Link}
                disabled={total > 0}
                to={createPath({
                  type: "edit",
                  resource,
                  id: record.id,
                })}
              >
                Edit
              </MenuItem>
            )
          }
        />
      </Menu>
    </ListBase>
  );
};

const QuestionChoiceListAsideCard = (props) => {
  const theme = useTheme();
  return (
    <Card sx={{ mt: "8px", mb: "3em", ml: "1em", width: asideWidth }}>
      <CardHeader
        action={props.action}
        sx={{
          backgroundColor: theme.palette.yellow.main,
          py: props.action ? "3px" : "7px",
        }}
        title={props.title}
        titleTypographyProps={{
          variant: "span",
          fontWeight: "bold",
        }}
      />
      <CardContent
        sx={{
          "& .RaLabeled-label": { fontWeight: "bold" },
          "& fieldset": {
            borderColor: theme.palette.yellow.main,
            borderRadius: "12px",
          },
          "& legend": {
            fontSize: "12px",
            fontWeight: "bold",
          },
        }}
      >
        {props.children || <Stack spacing={1} />}
      </CardContent>
    </Card>
  );
};

const QuestionChoiceListAside = (props) => {
  const { data, isPending, selectedIds } = useListContext();
  if (isPending) return null;
  if (!data) return null;
  if (!data.length) return null;
  const record = data?.find((e) => e.id === props.selection);
  if (!record)
    return (
      <QuestionChoiceListAsideCard
        title={`${selectedIds.length} ${inflect("choice", selectedIds.length)} selected`}
      />
    );
  if (!props.selection)
    return <QuestionChoiceListAsideCard title="No choice selected" />;
  return (
    <RecordContextProvider value={record}>
      <QuestionChoiceListAsideCard
        action={<AsideOptions />}
        title={record.value}
      >
        <Stack spacing={1}>
          {record.value && (
            <Box component="fieldset">
              <legend>Value</legend>
              <TextField source="value" />
            </Box>
          )}
          {record.description && (
            <Box component="fieldset">
              <legend>Description</legend>
              <TextField source="description" />
            </Box>
          )}
        </Stack>
      </QuestionChoiceListAsideCard>
    </RecordContextProvider>
  );
};

const exporter = (questionChoices, fetchRelatedRecords) => {
  const questionChoicesForExport = questionChoices.map((questionChoice) => {
    const { value, description } = questionChoice;
    return { value, description };
  });
  jsonExport(
    questionChoicesForExport,
    {
      headers: ["value", "description"],
      rename: ["Value", "Description"],
    },
    (err, csv) => {
      downloadCSV(csv, "questionChoices"); // download as 'questionChoices.csv` file
    },
  );
};

export const QuestionChoiceList = (props) => {
  const [selection, setSelection] = useStore(
    props.selectionStore ?? "questionChoice.list",
    null,
  );
  const theme = useTheme();

  return (
    <List
      {...props}
      aside={<QuestionChoiceListAside selection={selection} />}
      exporter={exporter}
      filters={[<SearchInput key="search" source="search" alwaysOn />]}
      sort={{ field: "created_at", order: "DESC" }}
      sx={{ m: 2 }}
    >
      <Datagrid
        bulkActionsToolbar={
          <BulkActionsToolbar label="1 choice selected |||| %{smart_count} choices selected">
            <BulkExportButton color="white" />
            <BulkDeleteButton
              mutationMode="pessimistic"
              color="white"
              deleteSuccessMessage="Choice deleted |||| %{smart_count} choices deleted"
            />
          </BulkActionsToolbar>
        }
        rowClick={(id, resource, record) => {
          setSelection(id);
          return false;
        }}
        rowSx={(record, index) => ({
          backgroundColor:
            record.id === selection ? theme.palette.lightYellow.main : null,
        })}
      >
        <TextField source="value" />
        <TextField source="description" />
      </Datagrid>
    </List>
  );
};

const FormToolbar = (props) => (
  <Toolbar sx={{ justifyContent: "right" }}>
    <SaveButton
      color="black"
      sx={{ textTransform: "none" }}
      icon={<SaveOutlinedIcon />}
    />
  </Toolbar>
);

const BackButton = ({ destination }) => {
  return (
    <Button
      component={Link}
      to={destination}
      color="yellow"
      variant="contained"
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 10,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 10,
        fontWeight: "bold",
        textTransform: "none",
      }}
      startIcon={<ArrowBackIcon />}
    >
      Back
    </Button>
  );
};

const QuestionChoiceForm = (props) => {
  const unique = useUnique();
  return (
    <SimpleForm sx={{ px: 3 }} toolbar={<FormToolbar />} {...props}>
      <FormDataConsumer>
        {({ formData }) => (
          <TextInput
            source="value"
            fullWidth
            validate={[
              required(),
              unique({
                filter: {
                  question: formData.question,
                },
                message: "Choice already exists for this question",
              }),
            ]}
          />
        )}
      </FormDataConsumer>
      <TextInput source="description" fullWidth multiline />
    </SimpleForm>
  );
};

export const QuestionChoiceCreate = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();
  const createPath = useCreatePath();
  const { state } = useLocation();

  const onSuccess = (data) => {
    notify("Choice created.");
    redirect("show", "questions", data.question);
  };

  const onError = (error) => {
    notify(error.message || "Could not create choice.", { type: "error" });
  };

  return (
    <Container maxWidth={false} disableGutters sx={{ my: { xs: 3 } }}>
      <BackButton
        destination={createPath({
          resource: "questions",
          type: "show",
          id: state?.record?.question,
        })}
      />
      <Container maxWidth="sm">
        <Create
          title="New Choice"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Choice"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <QuestionChoiceForm />
          </Card>
        </Create>
      </Container>
    </Container>
  );
};

export const QuestionChoiceEdit = (props) => {
  const theme = useTheme();
  const notify = useNotify();
  const redirect = useRedirect();
  const createPath = useCreatePath();

  const onSuccess = (data) => {
    notify("Choice updated.");
    redirect("show", "questions", data.question);
  };

  const onError = (error) => {
    notify(error.message || "Could not update choice.", { type: "error" });
  };

  return (
    <Container maxWidth={false} sx={{ my: { xs: 3 }, px: { xs: 0 } }}>
      <EditBase {...props}>
        <WithRecord
          render={(record) => (
            <BackButton
              destination={createPath({
                resource: "questions",
                type: "show",
                id: record.question,
              })}
            />
          )}
        />
      </EditBase>
      <Container maxWidth="sm" sx={{}}>
        <Edit
          title="Edit Choice"
          mutationMode="pessimistic"
          mutationOptions={{ onSuccess, onError }}
          sx={{ mt: { xs: 3 } }}
          {...props}
        >
          <Card>
            <CardHeader
              sx={{
                backgroundColor: theme.palette.yellow.main,
                py: 1,
                textAlign: "center",
              }}
              title="Choice"
              titleTypographyProps={{
                fontWeight: "bold",
                variant: "span",
              }}
            />
            <QuestionChoiceForm />
          </Card>
        </Edit>
      </Container>
    </Container>
  );
};
